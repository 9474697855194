import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from './session.service';
import { ClientLifecycle, GlobalRoles, Tenant } from '@parashift/shared/models';
import { AllPermissions, AllRoles } from '@parashift/shared/constants';
import { CurrentTenantRegister } from './registers/current-tenant.register';

export interface CheckPermissions {
  roles?: AllRoles[];
  permissions?: AllPermissions[];
  ownerTenantId?: string;
  explicitlyShow?: boolean;
  checkParashiftPowers?: boolean;
}

export interface PermissionsValidation {
  roleIsAllowed?: boolean;
  validPermissions?: boolean;
  validTenantOwnerPermissions?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private sessionService: SessionService,
    private currentTenantRegister: CurrentTenantRegister,
    private route: ActivatedRoute
  ) {}

  isUserRoleAllowed(roles: GlobalRoles[]): boolean {
    if (roles?.length > 0) {
      return roles.some(role => this.sessionService.global_roles.indexOf(role) >= 0);
    } else {
      return false;
    }
  }

  checkUsersTenantPermissions(permissions: AllPermissions[]): boolean {
    const current_tenant = this.currentTenantRegister.tenant;

    if (current_tenant?.id) {
      const user_tenant = this.sessionService.user.tenants.find(tenant => Number(tenant.id) === Number(current_tenant.id));

      if (!user_tenant) {
        return false;
      }

      return permissions.some(permission => user_tenant.permissions.indexOf(permission) >= 0);
    }

    return false;
  }

  isTenantOwner(checkPermissions: CheckPermissions): boolean {
    if (this.checkUsersOwnedTenantPermissions(checkPermissions)) {
      return true;
    }

    this.removePermissionsIfValidTenantOwner(checkPermissions);
    return false;
  }

  private checkUsersOwnedTenantPermissions(checkPermissions: CheckPermissions): boolean {
    const user_tenant = this.sessionService.user.tenants.find(tenant => Number(tenant.id) === Number(checkPermissions.ownerTenantId));

    if (!user_tenant) {
      return false;
    }

    return checkPermissions.permissions.some(permission => user_tenant.permissions.indexOf(permission) >= 0);
  }

  isTenantAllowed(tenants: string[] = []): boolean {
    const current_tenant = this.currentTenantRegister.tenant;

    if (current_tenant?.id && tenants.includes(current_tenant.id)) {
      return true;
    }

    return false;
  }

  isAgent(): boolean {
    const tenant_id = this.route.firstChild.snapshot.paramMap.get('tenant_id');

    if (
      tenant_id
      && this.currentTenantRegister.tenant?.id === tenant_id
      && this.currentTenantRegister.tenant?.agent
    ) {
      return true;
    }

    return false;
  }

  getUsersTenantPermissions() {
    const current_tenant = this.currentTenantRegister.tenant;
    if (current_tenant?.id) {
      const user_tenant = this.sessionService.user.tenants.find(tenant => Number(tenant.id) === Number(current_tenant.id));

      if (!user_tenant) {
        return [];
      }

      return user_tenant.permissions;
    }

    return false;
  }

  validatePermissions(checkPermissionsOrigin: CheckPermissions): PermissionsValidation {
    if (!checkPermissionsOrigin) {
      // if no checkPermissions defined, allow all
      return {
        roleIsAllowed: true,
        validPermissions: true,
        validTenantOwnerPermissions: true,
      };
    }

    const checkPermissions = {
      ...checkPermissionsOrigin,
      roles: [...checkPermissionsOrigin.roles ?? []],
      permissions: [...checkPermissionsOrigin.permissions ?? []],
    };

    let roleIsAllowed = false;
    let validPermissions = false;
    let validTenantOwnerPermissions = false;

    if (
      Array.isArray(checkPermissions?.roles) &&
      checkPermissions.roles.length > 0 &&
      this.isUserRoleAllowed(checkPermissions.roles)
    ) {
      roleIsAllowed = true;
    }

    if (
      checkPermissions.ownerTenantId?.length > 0 &&
      this.isTenantOwner(checkPermissions)
    ) {
      validTenantOwnerPermissions = true;
    }

    if (
      Array.isArray(checkPermissions.permissions) &&
      checkPermissions.permissions.length > 0 &&
      this.checkUsersTenantPermissions(checkPermissions.permissions)
    ) {
      validPermissions = true;
    }

    if (!checkPermissionsOrigin.roles?.length && !checkPermissionsOrigin.permissions?.length  && !checkPermissionsOrigin.ownerTenantId?.length) {
      validPermissions = true;
    }

    return { roleIsAllowed, validPermissions, validTenantOwnerPermissions };
  }

  isGlobalRoleAllowedWithTenant(tenant: Tenant['plainModel']): boolean {
    return this.sessionService.global_roles.includes('admin')
      || (this.sessionService.global_roles.includes('support') && !tenant.secured)
      || (
        this.sessionService.global_roles.includes('sales') && !tenant.secured
          && (tenant.client_lifecycle === 'fast_path_to_value' as ClientLifecycle || tenant.client_lifecycle === 'trial' as ClientLifecycle)
      );
  }

  private removePermissionsIfValidTenantOwner(checkPermissions: CheckPermissions) {
    const ownerPermissions = ['admin', 'owner', 'pdc.audit_owned_tasks', 'pdc.delete_owned', 'pdc.export_owned', 'pdc.own', 'pdc.reports_owned', 'pdc.reset_owned'];

    if (
      Array.isArray(checkPermissions.permissions) &&
      checkPermissions.permissions.length > 0
    ) {
      checkPermissions.permissions = checkPermissions.permissions.filter(permission => !ownerPermissions.includes(permission));
    }
  }

  isUserMemberOfTenant(tenantId: string): boolean {
    const user_tenant = this.sessionService.user.tenants.find(userTenant => userTenant.id === Number(tenantId));
    return user_tenant ? true : false;
  }

  isGlobalRoleSales(): boolean {
    return !this.sessionService.global_roles.includes('admin')
      && !this.sessionService.global_roles.includes('support')
      && this.sessionService.global_roles.includes('sales');
  }
}
